// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/jsoneditor/dist/jsoneditor.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".no-margin{margin:0}#mainContainer{height:100vh;display:flex;flex-direction:column;justify-content:space-between}#navContainer{display:flex;column-gap:10px;flex-direction:row}#editorsContainer{display:flex;flex-direction:row;height:100%}#container{height:100%;width:100%}#container2{height:100%;width:100%}.inline{display:inline}", "",{"version":3,"sources":["webpack://./src/TheApp.vue"],"names":[],"mappings":"AAGI,WACI,QAAA,CAGJ,eACI,YAAA,CACA,YAAA,CACA,qBAAA,CACA,6BAAA,CAGJ,cACI,YAAA,CACA,eAAA,CACA,kBAAA,CAQJ,kBACI,YAAA,CACA,kBAAA,CACA,WAAA,CAGJ,WACI,WAAA,CACA,UAAA,CAGJ,YACI,WAAA,CACA,UAAA,CAGJ,QACI,cAAA","sourcesContent":["\n    @import 'jsoneditor/dist/jsoneditor.css';\n\n    .no-margin {\n        margin: 0;\n    }\n\n    #mainContainer {\n        height: 100vh;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n    }\n\n    #navContainer {\n        display: flex;\n        column-gap: 10px;\n        flex-direction: row;\n    }\n\n    #log {\n        //height: 100px;\n        //width: 100%;\n    }\n\n    #editorsContainer {\n        display: flex;\n        flex-direction: row;\n        height: 100%;\n    }\n\n    #container {\n        height: 100%;\n        width: 100%;\n    }\n\n    #container2 {\n        height: 100%;\n        width: 100%;\n    }\n\n    .inline {\n        display: inline;\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
