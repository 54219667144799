<template>
    <div id="mainContainer">
        <div id="navContainer">
            <button @click="connect" :disabled="connected">Установить соединение</button>
            <button @click="disconnect" :disabled="!connected">Разорвать соединение</button>
            <button @click="send" :disabled="!connected">Отправить JSON</button>
            <input type="url" v-model="url">
            <div>
                <div class="inline">Переподключение</div>
                <input class="inline" type="checkbox" v-model="reconnect">
            </div>
            <div>
              <div class="inline">Авто обновление</div>
              <input class="inline" type="checkbox" v-model="autoUpdate">
            </div>
            <button @click="showJson" :disabled="!connected || autoUpdate">Просмотреть JSON</button>
            <div>
              <div class="inline">Авто отправка</div>
              <input class="inline" type="checkbox" v-model="autoSend">
              <input type="number" v-model="autoSendTimeout" style="width: 70px;" />
            </div>
        </div>
        <div id="editorsContainer">
            <div id="container" ref="container"></div>
            <div id="container2" ref="container2"></div>
        </div>
        <div>
            Прогресс {{progress}} | Сообщений {{messages}} | Размер сообщения {{messageLength}} кб | Всего принято {{allDataLength.toFixed(2)}} мб
        </div>
        <div>Окно логов</div>
        <textarea v-model="logData"  id="log"
                  rows="10" >
        </textarea>
    </div>
</template>

<script>
import JSONEditor from 'jsoneditor';

export default {
    name: 'App',
    components: {

    },
    data: function() {
        return {
            url: "ws://localhost:8085",
            connected: false,
            logData: "",
            progress: 0,
            messages: 0,
            allDataLength: 0,
            messageLength: 0,
            reconnect: true,
            autoSend: false,
            autoSendTimeout: 100,
            autoUpdate: true,
            json: {
                "String": "Тестовый JSON с разными типами данных",
                "Array": [1, 2, 3],
                "Boolean": true,
                "Null": null,
                "Number": 123,
                "Object": {"a": "b", "c": "d"}
            },
            json2: { "1": "В этом окне будет JSON от сервера",
                "2": "А пока ждем сообщений"},
        };
    },
    computed: {

    },
    methods: {
        log(message) {
            this.logData+=message+'\n';
        },
        disconnect() {
            this.reconnect = false;
            this.socket.close(1000, "Соединение закрыто на стороне клиента");
        },
        connect() {
            if (this.socket)
                this.socket.close(1000, "Соединение закрыто на стороне клиента");

            this.socket = new WebSocket(this.url);
            this.socket.onopen = (e) => {
                this.log("Соединение установлено");
                this.connected = true;
            };

            this.socket.onmessage = (event) => {
                // this.log(`Данные получены с сервера:\n ${event.data}`);
                // try {
                //     this.json2 = JSON.parse(event.data);
                // } catch {
                //     this.log("Полученные данные не в JSON формате")
                //     this.json2 = {"not JSON": event.data };
                // }
                // this.jsoneditor2.set(this.json2);
                // console.log('Прием ',event.data.length);
                let jsonData = '';
                try {
                  jsonData = JSON.parse(event.data);
                } catch (e) {
                  this.log("Не валидный JSON")
                  this.log(event.data)
                  return;
                }

                this.progress = jsonData.progress;
                this.messages += 1;
                this.messageLength = event.data.length / 1000;
                this.allDataLength += event.data.length / 1000000;
                this.json2 = jsonData;
                if (this.autoUpdate)
                  this.jsoneditor2.set(this.json2);
                // this.jsoneditor2.set(jsonData);
            };

            this.socket.onclose = (event) => {
                if (event.wasClean) {
                    this.log(`[close] Соединение закрыто штатно, код=${event.code} причина=${event.reason}`);
                } else {
                    this.log('[close] Соединение прервано');
                }
                this.connected = false;
                if (this.reconnect) this.connect();
            };

            this.socket.onerror = (error) => {
                this.log(`[error]`);
            };
        },
        send() {
            this.socket.send(JSON.stringify(this.json));
            if (this.autoSend) {
              setTimeout(() => {
                this.send();
              }, this.autoSendTimeout);
            }
            this.log("Отправлен JSON на сервер");
        },
        showJson() {
            this.jsoneditor2.set(this.json2);
        }
    },
    mounted: function() {
        const options = {
            mode: 'code',
            onChangeJSON: (data)=>{
                console.log(data)
                this.json = data
            },
            onChangeText: (jsonText)=>{
                try {
                    this.json = JSON.parse(jsonText);
                } catch {

                }
            }
        };
        const options2 = {
            mode: 'code',

        };

        this.jsoneditor = new JSONEditor(this.$refs.container, options);
        this.jsoneditor.set(this.json);

        this.jsoneditor2 = new JSONEditor(this.$refs.container2, options2);
        this.jsoneditor2.set(this.json2);
    },
};
</script>

<style>
    @import 'jsoneditor/dist/jsoneditor.css';

    .no-margin {
        margin: 0;
    }

    #mainContainer {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #navContainer {
        display: flex;
        column-gap: 10px;
        flex-direction: row;
    }

    #log {
        //height: 100px;
        //width: 100%;
    }

    #editorsContainer {
        display: flex;
        flex-direction: row;
        height: 100%;
    }

    #container {
        height: 100%;
        width: 100%;
    }

    #container2 {
        height: 100%;
        width: 100%;
    }

    .inline {
        display: inline;
    }
</style>
